import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { DepositResult } from '../DepositResult'
import { ThunkDispatch } from 'redux-thunk'
import { WalletCheckout } from '../WalletCheckout'
import { ErrorDisplay, Preloader } from 'mmfintech-portal-commons'
import {
  CheckoutContainer,
  CheckoutContent,
  CoreInput,
  MerchantPane,
  SelectMethod,
  CoreSelect,
  CoreButton,
  PaymentWrapper
} from '../../components'

import { actions, CheckoutFlowStep, useCheckout, useSessionId, useUnloadBeacon } from 'mmfintech-checkout-commons'
import { getCountryAlpha2, isEmptyString, isValidArray, tr, translateCountryName } from 'mmfintech-commons'

import { CountryChoice, PaymentMethodEnum, PaymentSessionStatusEnum, SupportedBank } from 'mmfintech-commons-types'
import { WalletCheckoutOtp } from '../WalletCheckout/WalletCheckoutOtp'

const Checkout = () => {
  const { sessionId } = useSessionId()

  const {
    logo,
    banks,
    checkoutCurrencies,
    checkoutCountries,
    checkoutCountriesError,
    checkoutError,
    checkoutPay,
    checkoutPayFetching,
    session,
    sessionFetching
  } = useSelector(
    ({
      checkout: {
        logo,
        banks,
        checkoutCurrencies,
        checkoutCountries,
        checkoutCountriesError,
        checkoutError,
        checkoutPay,
        checkoutPayFetching,
        session,
        sessionFetching
      }
    }) => ({
      logo,
      banks,
      checkoutCurrencies,
      checkoutCountries,
      checkoutCountriesError,
      checkoutError,
      checkoutPay,
      checkoutPayFetching,
      session,
      sessionFetching
    }),
    shallowEqual
  )

  const [storageAccess, setStorageAccess] = useState(false)

  const history = useHistory()
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const { sessionStatus, paymentResult, paymentResultType, processingAmount, processingCurrency } = session || {}
  useUnloadBeacon({ sessionId })
  const showCancelButton = true
  const {
    step,
    fields,
    status,
    logEvent,
    formValues,
    countryCode,
    setCountryCode,
    paymentMethod,
    setPaymentMethod,
    handleStartOver,
    handlePay,
    handlePayWithWallet,
    handlePaymentStatusResponse
  } = useCheckout({
    enableLogging: true,
    enableKingdomWalletStep: true,
    initialStep: CheckoutFlowStep.SELECT_METHOD
  })

  const handleCancelClick = () => {
    if (sessionId) {
      void dispatch(actions.checkout.cancelSessionPayment(sessionId))
    }
    logEvent('cancelled_by_payer')
    history.push('/fail')
    // return false
  }

  const handleBankSelected = (code: string) => {
    logEvent('bank_selected', code)
  }

  const parseResult = () => {
    try {
      return JSON.parse(paymentResult)
    } catch (error) {
      return paymentResult
    }
  }

  const prepareResponse = () =>
    sessionStatus?.value === PaymentSessionStatusEnum.IN_PROGRESS
      ? { result: parseResult(), resultType: paymentResultType, processingAmount, processingCurrency }
      : checkoutPay

  useEffect(() => {
    if (typeof document.hasStorageAccess === 'function') {
      document.hasStorageAccess().then(response => setStorageAccess(response))
    } else {
      setStorageAccess(true)
    }
  }, [])

  return (
    <CheckoutContainer>
      <CheckoutContent>
        <ErrorDisplay error={checkoutCountriesError} />

        {sessionFetching ? (
          <PaymentWrapper>
            <Preloader />
          </PaymentWrapper>
        ) : (
          <>
            {step !== CheckoutFlowStep.KINGDOM_WALLET && <MerchantPane session={session} logo={logo} />}

            {(step === CheckoutFlowStep.SELECT_METHOD || step === CheckoutFlowStep.ADDITIONAL_FIELDS) && (
              <>
                <PaymentWrapper>
                  {status === PaymentSessionStatusEnum.IDLE && (
                    <>
                      {checkoutCountries?.length > 1 ? (
                        <div className='mb-3'>
                          <CoreSelect
                            type='country'
                            name='countryCode'
                            label={tr('CHECKOUT.PAYMENT.COUNTRY_PLACEHOLDER', 'Select country')}
                            value={countryCode}
                            options={checkoutCountries?.map((country: CountryChoice) => ({
                              value: country.countryCode,
                              label: country.name
                            }))}
                            onChange={(_name: string, value: string) => {
                              return setCountryCode(value)
                            }}
                            required
                          />
                        </div>
                      ) : (
                        <div className='mb-3'>
                          <CoreInput
                            name='countryCode'
                            label={tr('CHECKOUT.PAYMENT.COUNTRY', 'Country')}
                            value={translateCountryName(countryCode)}
                          />
                        </div>
                      )}

                      <SelectMethod method={paymentMethod} setMethod={setPaymentMethod} countryCode={countryCode} />

                      <div className='mt-2' />
                      {isValidArray(fields) && paymentMethod !== PaymentMethodEnum.KINGDOM_WALLET ? (
                        <div className='payment-fields-wrapper'>
                          {fields.map((item, index) => {
                            const { name, defaultLabel, translationKey, required, sensitive } = item

                            if (name === 'bankChoiceId') {
                              return (
                                <CoreSelect
                                  key={index}
                                  type='default'
                                  label={tr('CHECKOUT.PAYMENT.BANK', 'Bank')}
                                  {...formValues.registerInput(name, handleBankSelected)}
                                  options={banks?.map((bank: SupportedBank) => {
                                    const { bankChoiceId, name } = bank
                                    return { value: bankChoiceId, label: name }
                                  })}
                                  required={required}
                                  hideErrorLine
                                />
                              )
                            }

                            if (name === 'currency') {
                              return (
                                <CoreSelect
                                  key={index}
                                  type='currency'
                                  label={tr(translationKey, defaultLabel)}
                                  {...formValues.registerInput(name)}
                                  options={checkoutCurrencies?.map((currency: string) => {
                                    return { value: currency, label: currency }
                                  })}
                                  onChange={(name, value) => formValues.setValue(name, value.value)}
                                  required={required}
                                  hideErrorLine
                                />
                              )
                            }

                            if (name === 'documentId' && paymentMethod === 'PIX') {
                              return (
                                <CoreInput
                                  key={index}
                                  type={'text'}
                                  label={tr(translationKey, defaultLabel)}
                                  {...formValues.registerShort(name)}
                                  onChange={(name: string, value: string) =>
                                    formValues.setValue(name, value.replace(/[ ./-]/g, ''))
                                  }
                                  required={required}
                                  hideErrorLine
                                  autoComplete='off'
                                />
                              )
                            }

                            if (name === 'phone') {
                              return (
                                <CoreInput
                                  type='phone'
                                  data-test={`${name}-input`}
                                  label={tr(translationKey, defaultLabel)}
                                  {...formValues.registerInput(name)}
                                  required={required}
                                  autoComplete='off'
                                  {...(paymentMethod === 'INTERAC'
                                    ? {
                                        country: 'ca',
                                        onlyCountries: ['ca'],
                                        countryCodeEditable: false,
                                        disableDropdown: true
                                      }
                                    : {
                                        country: getCountryAlpha2(countryCode)?.toLowerCase()
                                      })}
                                />
                              )
                            }

                            return (
                              <CoreInput
                                key={index}
                                type={sensitive ? 'password' : 'text'}
                                label={tr(translationKey, defaultLabel)}
                                {...formValues.registerInput(name)}
                                required={required}
                                autoComplete='off'
                              />
                            )
                          })}
                        </div>
                      ) : null}
                    </>
                  )}

                  <ErrorDisplay error={checkoutError} />

                  {status === PaymentSessionStatusEnum.IDLE && (
                    <div className='buttons'>
                      <CoreButton
                        type='button'
                        fullWidth
                        variation='primary'
                        text={tr('CHECKOUT.PAYMENT.BUTTON_PAY', 'Pay')}
                        onClick={handlePay}
                        disabled={isEmptyString(paymentMethod)}
                        isLoading={checkoutPayFetching}
                        data-test='button-pay'
                      />
                      {showCancelButton && (
                        <CoreButton
                          type='button'
                          variation='tertiary'
                          text={tr('CHECKOUT.BUTTONS.CANCEL', 'Cancel')}
                          onClick={handleCancelClick}
                          isLoading={checkoutPayFetching}
                          data-test='button-pay'
                        />
                      )}
                    </div>
                  )}
                </PaymentWrapper>
              </>
            )}
          </>
        )}

        {step === CheckoutFlowStep.KINGDOM_WALLET && (
          <WalletCheckout
            sessionId={sessionId}
            onCancel={handleStartOver}
            handlePay={handlePayWithWallet}
            hasStorageAccess={storageAccess}
            setStorageAccess={setStorageAccess}
          />
        )}

        {step === CheckoutFlowStep.WALLET_OTP && (
          <WalletCheckoutOtp onSuccess={response => handlePaymentStatusResponse(response)} />
        )}

        {(step === CheckoutFlowStep.DEPOSIT_RESULT || true) && (
          <DepositResult session={session} sessionId={sessionId} response={prepareResponse()} logEvent={logEvent} />
        )}
      </CheckoutContent>
    </CheckoutContainer>
  )
}

export default Checkout
