import styled from 'styled-components'

export const StyledForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  .input-wrapper,
  .button {
    width: 100%;
  }

  .login-title {
    color: rgba(43, 49, 58, 1);
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .account-title {
    margin-bottom: 3rem;
  }

  .login-form-fields {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .login-qr-code-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  .login-qr-code-container {
    padding: 5rem;

    background: #f7f8fa;
    box-shadow:
      6px 4px 8.2px -1px rgba(30, 41, 59, 0.1),
      -14px -10px 8px rgba(255, 255, 255, 0.6),
      inset 7px 7px 4px rgba(0, 0, 0, 0.03),
      inset -5px -2px 4px rgba(255, 255, 255, 0.6);
    border-radius: 24px;
  }

  .login-buttons {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    button {
      width: 100%;
      padding-left: 3rem;
      padding-right: 3rem;
    }

    @media (min-width: 640px) {
      flex-direction: row;

      button {
        width: unset;
      }
    }
  }

  .wallet-checkout-accounts {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
  }
`
