import { ReactNode, useContext } from 'react'
import { useDispatch } from 'react-redux'

import { actions, useSessionId } from 'mmfintech-checkout-commons'

// import { SupportBlock } from '../SupportBlock'
import { ThemeContext } from 'styled-components'
import { CheckoutHeader, CheckoutLanguage, CheckoutWrapper } from './CheckoutContainer.styled'

import { ThunkDispatch } from 'redux-thunk'
import { LanguageMenu } from '../LanguageMenu'

type CheckoutContainerProps = {
  children: ReactNode
}

export const CheckoutContainer = ({ children }: CheckoutContainerProps) => {
  const themeContext = useContext(ThemeContext)

  const { sessionId } = useSessionId()

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const handleLanguageChanged = (languageCode: string): void => {
    void dispatch(actions.common.storeSelectedLanguage(sessionId, languageCode))
  }

  return (
    <CheckoutWrapper>
      <CheckoutHeader>
        <span className='logo'>
          <img src={themeContext.brandLogo} alt='Logo' />
        </span>
      </CheckoutHeader>
      <CheckoutLanguage>
        <LanguageMenu onChange={handleLanguageChanged} />
      </CheckoutLanguage>
      {children}
      {/*<SupportBlock /> -- hidden by Svilen's request */}
    </CheckoutWrapper>
  )
}
