import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { tr, useFormValues } from 'mmfintech-commons'
import { actions, configuration } from 'mmfintech-checkout-commons'

import { QRCodeSVG } from 'qrcode.react'
import { StyledForm } from '../WalletCheckout.styled'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { ThunkDispatch } from 'redux-thunk'
import { CoreButton, CoreInput } from '../../../components'
import { PaymentContainer, SignupLinkWrapper } from '../../CheckoutPayment/Checkout.styled'

import LogoImage from '../../../assets/icons/logo.svg'
// import PhoneImage from '../../../assets/icons/phone.svg'

export const WalletCheckoutLogin = ({ sessionId, session, onCancel, onSuccess }) => {
  const { loginError, loginFetching } = useSelector(
    ({ auth: { loginError, loginFetching } }) => ({ loginError, loginFetching }),
    shallowEqual
  )

  const [step, setStep] = useState<number>(1)

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const loginValues = useFormValues({
    email: { required: true, validation: 'email' },
    password: { required: true }
  })

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (loginValues.areValid()) {
      void dispatch(actions.auth.login(sessionId, loginValues.prepare(), onSuccess))
    }
  }

  const getLink = () => {
    const { amount, currency } = session || {}
    return `${preparePortalLink('wallet-payment')}?sessionId=${sessionId}&amount=${amount}&currency=${currency}`
  }

  const preparePortalLink = (path: string): string => {
    const host = configuration.readBackendConfig().replace('pay', 'portal')
    return `${host}${path.substring(0, 1) === '/' ? '' : '/'}${path}`
  }

  return (
    <>
      <PaymentContainer data-test='wallet-checkout-login'>
        {step === 1 ? (
          <StyledForm noValidate onSubmit={handleLogin}>
            <div className='login-title'>{tr('CHECKOUT.LOGIN.SIGN_IN', 'Sign In')}</div>

            <div className='login-form-fields'>
              <CoreInput
                id='email'
                type='email'
                data-test='email'
                label={tr('CHECKOUT.LOGIN.EMAIL', 'Email')}
                {...loginValues.registerInput('email')}
                disabled={loginFetching}
                hideErrorLine
                autoFocus
              />

              <CoreInput
                id='password'
                type='password'
                data-test='password'
                label={tr('CHECKOUT.LOGIN.PASSWORD', 'Password')}
                {...loginValues.registerInput('password')}
                disabled={loginFetching}
                autoComplete='off'
                hideErrorLine
              />
            </div>

            <div className='link-wrap' data-test='forgot-password'>
              <a href={preparePortalLink('forgot-password')} target='_blank' rel='noopener noreferrer'>
                {tr('CHECKOUT.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}
              </a>
            </div>

            <ErrorDisplay error={loginError} />
            <div className='login-buttons'>
              <CoreButton
                type='submit'
                variation='primary'
                fullWidth
                className='mb-1'
                text={tr('CHECKOUT.LOGIN.BUTTON_LOGIN', 'Log in')}
                isLoading={loginFetching}
                data-test='button-submit'
              />
              {/*<CoreButton
                type='button'
                variation='primary'
                className='mb-1 px-4'
                text={
                  <>
                    <img src={PhoneImage} alt='Phone' />
                    {tr('CHECKOUT.LOGIN.BUTTON_MOBILE', 'Continue on mobile')}
                  </>
                }
                onClick={() => setStep(2)}
                disabled={loginFetching}
                data-test='button-mobile'
              />*/}
              <CoreButton
                type='button'
                variation='tertiary'
                text={tr('CHECKOUT.BUTTONS.BACK', 'Back')}
                disabled={loginFetching}
                onClick={onCancel}
                data-test='button-back'
              />
            </div>
          </StyledForm>
        ) : (
          <StyledForm>
            <div className='login-title'>
              {tr('CHECKOUT.LOGIN.SCAN_CODE', 'Scan the code with The Kingdom Bank app')}
            </div>

            <div className='login-form-fields'>
              <div className='login-qr-code-wrapper'>
                <div className='login-qr-code-container'>
                  <QRCodeSVG
                    value={getLink()}
                    size={192}
                    imageSettings={{ src: LogoImage, height: 42, width: 42, excavate: true }}
                  />
                </div>
              </div>
            </div>

            <div className='login-buttons'>
              <CoreButton
                type='button'
                variation='primary'
                className='mb-1'
                fullWidth
                text={tr('CHECKOUT.LOGIN.BUTTON_WEB', 'Continue on web')}
                onClick={() => setStep(1)}
                data-test='button-mobile'
              />
              <CoreButton
                type='button'
                variation='tertiary'
                text={tr('CHECKOUT.BUTTONS.BACK', 'Back')}
                onClick={onCancel}
                data-test='button-back'
              />
            </div>
          </StyledForm>
        )}
      </PaymentContainer>

      <SignupLinkWrapper>
        {tr('CHECKOUT.LOGIN.NO_ACCOUNT', "Don't have an account?")}
        <a href={preparePortalLink('signup')} data-test='sign-up' target='_blank' rel='noopener noreferrer'>
          <span>
            {tr('CHECKOUT.LOGIN.BUTTON_SIGNUP', 'Sign up')} <span className='arrow'>→</span>
          </span>
        </a>
      </SignupLinkWrapper>
    </>
  )
}
