import { ErrorIcon } from '../../icons'
import { CoreButton } from '../CoreButton'
import { AlertContent, AlertWrapper } from './ErrorFallback.styled'

export const ErrorFallback = ({ resetErrorBoundary }) => {
  return (
    <AlertWrapper>
      <AlertContent>
        <ErrorIcon />

        <p>An unexpected error occurred</p>

        {resetErrorBoundary ? (
          <CoreButton variation='tertiary' onClick={resetErrorBoundary} text='Go back to the home page' />
        ) : null}
      </AlertContent>
    </AlertWrapper>
  )
}
