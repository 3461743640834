import styled from 'styled-components'

export const CoreButtonWrapper = styled.button`
  height: 4.8rem;
  width: 16.9rem;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal !important;
  line-height: 20.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.6rem;
  gap: 0.8rem;
  font-family: #{$font};

  &.large-primary-button {
    padding: 0 1rem 0 1rem;
    min-width: fit-content;
    white-space: nowrap;
    background-color: rgb(239, 68, 68);
    border: none;
    outline: none;
    color: #ffffff;

    &:hover {
      background-color: rgba(239, 68, 68, 0.75);
    }

    &:active {
      background-color: rgba(239, 68, 68, 0.85);
    }

    &:disabled {
      cursor: default;
      color: rgba(239, 68, 68, 0.3);
      background-color: rgba(239, 68, 68, 0.05);
    }

    .primary-button-icon {
      font-size: 32px;
    }

    &.loading {
      cursor: progress;
      height: 4.8rem;
      width: 4.8rem;

      span {
        background-color: #ffffff;
      }
    }

    &.collapsed {
      height: 3.6rem;
      width: 7rem;
      min-width: auto;
      border-radius: 3.1rem;
    }
  }
  .loading-button {
    display: flex;
    justify-content: center;
    text-align: center;

    .dot1 {
      -webkit-animation-delay: 0s !important;
      animation-delay: 0s !important;
    }

    .dot2 {
      -webkit-animation-delay: 0.2s !important;
      animation-delay: 0.2s !important;
    }

    .dot3 {
      -webkit-animation-delay: 0.4s !important;
      animation-delay: 0.4s !important;
    }

    .dot4 {
      -webkit-animation-delay: 0.6s !important;
      animation-delay: 0.6s !important;
    }

    span {
      display: flex;
      max-width: 30px;
      justify-content: center;
      width: 2px;
      height: 2px;
      background-color: var(--loading-button-dot-color);
      border-radius: 50px;
      -webkit-animation: loader 0.6s infinite alternate;
      animation: loader 0.6s infinite alternate;
    }
  }

  &.large-tertiary-button {
    padding: 0 1rem 0 1rem;
    min-width: fit-content;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid #d8d9dc;
    color: #2b313a;
    outline: none;

    &:hover {
      border: 1px solid transparent;
      background-color: #ffffff;
    }

    &:active {
      border: 1px solid transparent;
      background-color: #ecedef;
    }

    &:disabled {
      border: 1px solid transparent;
      cursor: default;
      color: rgba(#2b313a, 0.3);
      background-color: #ecedef;
    }

    .primary-button-icon {
      font-size: 32px;
    }

    &.loading {
      height: 4.8rem;
      width: 4.8rem;
      cursor: progress;

      span {
        background-color: #2b313a;
      }
    }
  }

  @-webkit-keyframes loader {
    0% {
      scale: 3;
    }
  }

  @keyframes loader {
    0% {
      scale: 3;
    }
  }
`
