import { formatMoney, tr } from 'mmfintech-commons'
import type { SelectionElementProps } from './selectionTypes'

import arrowDownIcon from '../../assets/icons/arrow-down.svg'
import { SelectionElementWrapper } from './SelectionElement.style'
import { CurrencyIcon } from '../CurrencyIcon'
import { CountryIcon } from '../CountryIcon'

export const SelectionElement = ({
  onSelect,
  data,
  type,
  setInputFocused,
  isSelected = false,
  hideCaption,
  inSelectedList = false,
  isCountryType
}: SelectionElementProps) => {
  switch (type) {
    case 'account':
      if (data === 'all-accounts') {
        return (
          <SelectionElementWrapper
            className={`selection-element-wrapper ${inSelectedList ? 'selected' : ''}`}
            onClick={() => {
              onSelect && onSelect(data)
            }}>
            {/* <img src={SelectAccountImage} width={30} /> */}
            <div className='selection-element-content pl-3'>
              <div data-test='selection-element-account-currency-code' className='body-medium'>
                {tr('CHECKOUT.SELECT.ALL_ACCOUNTS', 'All accounts')}
              </div>
              {isSelected && (
                <div data-test='selection-element-account-arrow-icon' className='selection-element-right-icon'>
                  <img src={arrowDownIcon} alt='' onClick={() => setInputFocused(true)} />
                </div>
              )}
            </div>
          </SelectionElementWrapper>
        )
      }
      return (
        <SelectionElementWrapper
          className={`selection-element-wrapper ${inSelectedList ? 'selected' : ''}`}
          onClick={() => {
            onSelect && onSelect(data)
            setInputFocused && setInputFocused(false)
          }}>
          {data?.currencyCode && <CurrencyIcon currency={data?.currencyCode} circle size='32px' />}
          <div className='selection-element-content pl-1'>
            <div data-test='selection-element-account-currency-code' className='body-medium'>
              {data?.name ||
                tr('CHECKOUT.SELECT.CURRENCY_ACCOUNT', '{{CURRENCY}} account', { CURRENCY: data?.currencyCode })}
            </div>
            {!hideCaption && (
              <div>
                <span data-test='selection-element-account-caption-label' className='selection-element-caption-label'>
                  {tr('CHECKOUT.SELECT.BALANCE', 'Balance')}
                  {': '}
                </span>
                <span data-test='selection-element-account-caption-value' className='selection-element-caption-text'>
                  {formatMoney(data?.balance, data?.currencyCode)}
                </span>
              </div>
            )}
            {isSelected && (
              <div data-test='selection-element-account-arrow-icon' className='selection-element-right-icon'>
                <img src={arrowDownIcon} alt='' onClick={() => setInputFocused(true)} />
              </div>
            )}
          </div>
        </SelectionElementWrapper>
      )
    case 'country':
      return (
        <SelectionElementWrapper
          className={`selection-element-wrapper ${inSelectedList ? 'selected' : ''}`}
          onClick={() => {
            onSelect && onSelect(data)
          }}>
          {data.value && <CountryIcon countryCode={data.value} borderless />}
          <div className='selection-element-content pl-3'>
            <div data-test='selection-element-country-label' className='body-medium'>
              {data.label}
            </div>
            {isSelected && (
              <div data-test='selection-element-country-icon' className='selection-element-right-icon'>
                <img src={arrowDownIcon} alt='' onClick={() => setInputFocused(true)} />
              </div>
            )}
          </div>
        </SelectionElementWrapper>
      )

    case 'currency':
      return (
        <SelectionElementWrapper
          className={`selection-element-wrapper ${inSelectedList ? 'selected' : ''}`}
          onClick={() => {
            onSelect && onSelect(data)
          }}
          key={`currency-${data?.value}`}>
          <div className={`selection-element-content currency ${isCountryType ? 'pl-3' : 'pl-1'}`}>
            {data?.currencyCode && <CurrencyIcon currency={data?.currencyCode} circle size='12px' />}
            <div data-test='selection-element-label' className='body-medium'>
              {data.label || data}
            </div>
            {isSelected && (
              <div data-test='selection-element-arrow-icon' className='selection-element-right-icon'>
                <img src={arrowDownIcon} alt='' onClick={() => setInputFocused(true)} />
              </div>
            )}
          </div>
        </SelectionElementWrapper>
      )

    case 'default':
      return (
        <SelectionElementWrapper
          className={`selection-element-wrapper ${inSelectedList ? 'selected' : ''}`}
          onClick={() => {
            onSelect && onSelect(data)
          }}>
          <div className={`selection-element-content ${isCountryType ? 'pl-3' : 'pl-1'}`}>
            <div data-test='selection-element-label' className='body-medium max-length-text'>
              {data.label || data}
            </div>
            {isSelected && (
              <div data-test='selection-element-arrow-icon' className='selection-element-right-icon'>
                <img src={arrowDownIcon} alt='' onClick={() => setInputFocused(true)} />
              </div>
            )}
          </div>
        </SelectionElementWrapper>
      )
  }
}
