import styled from 'styled-components'

export const CheckoutWrapper = styled.div`
  /* grid-row: 1 / 4;
  grid-column: left-column / right-column; */
  padding-top: 12.5rem;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  flex-direction: row;
  gap: 3.2rem;

  .err-msg-wrapper {
    justify-content: center;
  }

  @media (max-width: 1400px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  @media (max-width: 930px) {
    padding-top: 14.5rem;
  }

  .error-display-wrapper {
    font-size: 1.4rem;
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.4rem;
    }
  }
`

export const CheckoutHeader = styled.div`
  /* width: 100%; */
  left: 4rem;
  top: 4rem;
  position: absolute;
  /* height: 12rem; */
  /* display: grid; */
  /* grid-template-columns: repeat(3, 1fr); */

  .logo {
    width: 100%;
    height: 100%;
    grid-column: 2;
    display: grid;
    place-items: center;
  }

  .right {
    display: grid;
    place-items: center flex-end;
    margin-right: 6rem;
  }
`

export const CheckoutLanguage = styled.div`
  position: absolute;
  right: 2rem;
  top: 4rem;
`
